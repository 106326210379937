import React from "react";
import {
  CustomAutoComplete,
  CustomTextField,
} from "../../../components/inputs/input/BootStrapInput";
import { TextField } from "@mui/material";
import { useQuery } from "react-query";
import { api } from "../../../api/Intercepter";

const RadioShowFilter = ({ setRadioShow }) => {
  const { data, isLoading } = useQuery("radio-shows", async () => {
    const response = await api.get("/radio-shows", {
      params: {
        limit: 300,
      },
    });
    return response.data;
  });

  const other = {
    createdAt: "2023-11-20T14:57:02.378Z",
    deletedAt: null,
    description: "متفرقة",
    endDate: "2025-01-01T00:00:00.000Z",
    id: 0,
    radioShowName: "متفرقة",
    radioShowNameEng: "Voice of the Citizen ",
    season: 1,
    startDate: "2017-07-01T00:00:00.000Z",
    typeId: 1,
    updatedAt: "2024-06-04T12:28:21.643Z",
  };

  return (
    <CustomAutoComplete
      options={isLoading ? [] : [...data?.array, other]}
      size="small"
      onChange={(e, value: any) => {
        setRadioShow(value?.id);
      }}
      getOptionLabel={(option: any) => option.radioShowName}
      renderInput={(params) => (
        <TextField {...params} placeholder="اختر البرنامج" />
      )}
      sx={{ width: "300px" }}
    />
  );
};

export default RadioShowFilter;
