import { Button, useTheme } from "@mui/material";
import "./date-range-picker-mui.scss";

const DateRangePickerMui = ({
  startDate = "DD/MM/YYYY",
  endDate = "DD/MM/YYYY",
  handleClick,
  style,
}: any) => {

  const theme = useTheme();
  return (
    <Button
      style={style}
      className="input-range"
      onClick={handleClick}
      sx={{
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        textTransform: "none",
        padding: "10px 20px",
        color: theme.palette.text.primary,
        fontSize: "12px"
      }}
    >{`${startDate?.slice(0, 10)} - ${endDate?.slice(0, 10)}`}</Button>
  );
};

export default DateRangePickerMui;
