import { api } from "../Intercepter";

interface getAllHistoryParams {
  currentPage: number;
  limit: number;
  search: string;
  startDate: string;
  endDate: string;
  radioShow: string;
}

export const getAllHistory = async ({
  currentPage,
  limit,
  search,
  startDate,
  endDate,
  radioShow,
}: getAllHistoryParams) => {
  let response = await api.get(`/calls/history`, {
    params: {
      offset: currentPage * limit,
      limit: limit,
      q: search,
      since: startDate?.slice(0, 10),
      until: endDate,
      radioShowId: radioShow,
    },
  });

  if (!response) {
    throw new Error("Error in getting all phone book");
  }

  return response.data;
};
